package com.ektar.daas_sdk.core

import com.ektar.daas_sdk.model.CatalogDataPoint
import com.ektar.daas_sdk.utils.ApiConstants
import com.ektar.daas_sdk.utils.Constants
import com.ektar.daas_sdk.utils.Logger
import com.ektar.daas_sdk.utils.Utility
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.HTMLScriptElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import org.w3c.dom.url.URL
import kotlin.js.Date

@OptIn(ExperimentalJsExport::class)
@JsExport
actual class EktarCatalog(dataPoint: CatalogDataPoint) : EktarBanner(dataPoint) {

	private var catalogDataPoint: CatalogDataPoint = dataPoint

	private fun listenForMessage() {
		window.addEventListener("message", { event: Event ->
			val messageEvent = event as MessageEvent
			if (ApiConstants.isDaasProdOrNonProdUrl(messageEvent.origin)) {
				val message = messageEvent.data
				try {
					val response = JSON.parse<dynamic>(message.toString())
					if (response != null) {
						if (response.title === "saveEkIdInLocalStorage") {
							val messageValue = response.message
							if (messageValue != null) {
								localStorage.setItem("ekid", messageValue.toString())
								setCookie("ekid", messageValue.toString())
								dp.ekId = retrieveEkId()
							}
							checkIsDivLoadedAndBuildUrl()
						}
					}
				} catch (_: Throwable) {
				}
			}
		})
	}

	@Suppress("unused")
	actual fun loadOfferCatalog() {

		if (ektarDaasEnv == undefined) {
			Logger.logError("loadOfferCatalog", ApiConstants.envNotProvidedMessage)

		} else {

			ApiConstants.setEnvironment(ektarDaasEnv)

			val userAgent = window.navigator.userAgent
			dp.deviceType = getDeviceType()
			dp.deviceId = window.btoa(userAgent)
			dp.ipAddress = getIpAddress()
			dp.version = Utility.getSdkVersion()
			dp.platform = Utility.getPlatform()
			getLocation().then(onFulfilled = {
				if (it != null) {
					dp.latLng = it as String
				}
			}).catch {
			}.finally {
				val ekId = retrieveEkId()
				if (ekId.isBlank()) {
					val getEkIdIframe = document.createElement("iframe") as HTMLIFrameElement
					getEkIdIframe.src = ApiConstants.GET_EKID_API
					getEkIdIframe.let {
						it.style.position = "absolute"
						it.style.width = "0"
						it.style.height = "0"
						it.style.border = "0"
					}
					document.body?.appendChild(getEkIdIframe)
					getEkIdIframe.addEventListener("load", {
						getEkIdIframe.contentWindow?.postMessage(Constants.SAVE_EKID_IN_LOCAL_STORAGE,
							ApiConstants.GET_OFFER_API_V2)
						listenForMessage()
					}, true)
				} else {
					dp.ekId = ekId
					checkIsDivLoadedAndBuildUrl()
				}
			}
		}
	}

	private fun setCookie(name: String, value: String) {
		try {
			val daysToExpire = Date(8640000000000000).toUTCString()
			document.cookie = "$name=$value; expires=$daysToExpire; path=/;"
		} catch (e: Exception) {
			console.log(e.toString())
		}
	}

	private fun checkIsDivLoadedAndBuildUrl() {
		if (document.readyState.toString() == "loading") {
			try {
				document.addEventListener("DOMContentLoaded", EventListener {
					buildUrl()
				})
			} catch (e: Exception) {
				console.log("Exception", e.toString())
			}
		} else {
			buildUrl()
		}
	}

	private fun buildUrl() {
		if (document.getElementById(Constants.OFFER_CATALOG_ID) != null) {

			val iframeResizer = document.createElement("script") as HTMLScriptElement
			iframeResizer.src = "https://cdn.jsdelivr.net/npm/@iframe-resizer/parent@5.0.2"
			document.body?.appendChild(iframeResizer)

			iframeResizer.onload = {
				val iframeResizeInlineJs = document.createElement("script")
					as HTMLScriptElement
				iframeResizeInlineJs.innerHTML =
					"iframeResize({ warningTimeout: 0, license: \"1jy4dww5qzv-s54r73oxcn-v59f4kfgfz\" })"
				document.body?.appendChild(iframeResizeInlineJs)
			}

			val dismissLoader = document.createElement("script")
				as HTMLScriptElement
			dismissLoader.innerHTML = """
				function dismissLoader() {
			        var loader = document.getElementById("ek_loader");
					var ekIframe = document.getElementById("ek-iframe");
			        if (loader != null) {
					  loader.style.display = "none";
					  ekIframe.style.display = "flex";
					}
				  }
			""".trimIndent()
			document.body?.appendChild(dismissLoader)

			setProductTypeInDataPoint(catalogDataPoint)

			val url = catalogDataPoint.buildOfferCatalogUrl(this)
			loadContentToIFrameContainer(dp.buildIFrameSnippet(url, true))
		} else {
			Logger.logError(
				"loadOfferCatalog",
				ApiConstants.viewNotProvidedMessage
			)
		}
	}

	private fun loadContentToIFrameContainer(iframe: String) {
		val offerCatalogDiv =
			document.getElementById(Constants.OFFER_CATALOG_ID) as HTMLDivElement
		offerCatalogDiv.insertAdjacentHTML("beforeend", iframe)
	}

	private fun setProductTypeInDataPoint(catalogDataPoint: CatalogDataPoint) {
		val urlPath = window.location.href

		if (urlPath.isNotBlank()) {
			val url = URL(urlPath)
			val productType = url.searchParams.get("pt")
			if (!productType.isNullOrBlank()) {
				catalogDataPoint.productType = productType
			}
		}
	}
}
