package com.ektar.daas_sdk.model

import com.ektar.daas_sdk.core.EktarBanner
import com.ektar.daas_sdk.core.EktarCatalog
import com.ektar.daas_sdk.utils.ApiConstants
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.jvm.JvmSynthetic

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
open class DataPoint : UtmParameters() {
	// Merchant Id
	@SerialName("mid")
	var merchantId: String = ""

	// Page Id
	@SerialName("pgid")
	var pageId: String = ""

	// Visitor Id plain
	@SerialName("vid")
	var visitorId: String = ""

	// Hashed Visitor Id
	@SerialName("hvid")
	var hashedVisitorId: String = ""

	// EkId
	@SerialName("ekId")
	var ekId: String = ""

	// Email address
	@SerialName("e")
	var email: String = ""

	// Mobile number
	@SerialName("m")
	var mobile: String = ""

	// Device Id
	@SerialName("did")
	var deviceId: String = ""

	// Device Type
	@SerialName("dt")
	var deviceType: String = ""

	@SerialName("ip")
	var ipAddress: String = ""

	// Lat Long
	@SerialName("ll")
	var latLng: String = ""

	// Preferred Language
	@SerialName("pl")
	var preferredLanguage: String = ""

	// Nationality
	@SerialName("n")
	var nationality: String = ""

	// Merchant Customer Segment
	@SerialName("mcs")
	var merchantCustomerSegment: String = ""

	// Age
	@SerialName("a")
	var age: Double = 0.0

	// Member since (date)
	@SerialName("ms")
	var memberSince: String = ""

	// CC in Wallet
	@SerialName("ccw")
	var hasCCInWallet: Boolean = false

	// Credit Card Issuer Bank
	@SerialName("ccb")
	var ccBank: String = ""

	// Total spend so far
	@SerialName("ts")
	var totalSpend: Double = 0.0

	// Total spend last 3 months
	@SerialName("ts3m")
	var totalSpendInLast3Months: Double = 0.0

	// Total spend last 1 year
	@SerialName("ts1y")
	var totalSpendInLastYear: Double = 0.0

	// Reward Balance
	@SerialName("rb")
	var rewardBalance: Double = 0.0

	// Dimensions
	@SerialName("d")
	var dimension: String = ""

	// Subscriber
	@SerialName("sub")
	var subscriber: Boolean = false

	// Page category
	@SerialName("pc")
	var pageCategory: String = ""

	// Product type
	@SerialName("pt")
	var productType: String = ""

	// Version
	@SerialName("v")
	var version: String = ""

	// Platform
	@SerialName("p")
	var platform: String = ""


	@JvmSynthetic
	internal fun buildGetOfferRequestUrl(banner: EktarBanner): String {

		val isRequestFromEktarCatalog = banner is EktarCatalog

		val sb = StringBuilder()
		sb.append(
			if (isRequestFromEktarCatalog) {
				ApiConstants.GET_OFFER_CATALOG_API
			} else {
				ApiConstants.GET_OFFER_API_V2
			})
		sb.append("?")
		if (utmId.isNotBlank()) {
			sb.append("utm_id").append("=").append(banner.encodeUrl(utmId)).append("&")
		}
		if (utmSource.isNotBlank()) {
			sb.append("utm_source").append("=").append(banner.encodeUrl(utmSource)).append("&")
		}
		if (utmMedium.isNotBlank()) {
			sb.append("utm_medium").append("=").append(banner.encodeUrl(utmMedium)).append("&")
		}
		if (utmCampaign.isNotBlank()) {
			sb.append("utm_campaign").append("=").append(banner.encodeUrl(utmCampaign)).append("&")
		}

		if (merchantId.isNotBlank()) {
			sb.append("mid").append("=").append(banner.encodeUrl(merchantId)).append("&")
		}

		if (visitorId.isNotBlank()) {
			sb.append("vid").append("=").append(banner.encodeUrl(visitorId)).append("&")
		}
		if (hashedVisitorId.isNotBlank()) {
			sb.append("hvid").append("=").append(banner.encodeUrl(hashedVisitorId)).append("&")
		}

		if (pageId.isNotBlank()) {
			sb.append("pgid").append("=").append(banner.encodeUrl(pageId)).append("&")
		}
		if (dimension.isNotBlank()) {
			sb.append("d").append("=").append(dimension).append("&")
		}
		if (ekId.isNotBlank()) {
			sb.append("ekId").append("=").append(banner.encodeUrl(ekId)).append("&")
		}

		if (deviceId.isNotBlank()) {
			sb.append("did").append("=").append(banner.encodeUrl(deviceId)).append("&")
		}
		if (deviceType.isNotBlank()) {
			sb.append("dt").append("=").append(deviceType).append("&")
		}

		if (email.isNotBlank()) {
			sb.append("e").append("=").append(banner.encodeUrl(email)).append("&")
		}
		if (mobile.isNotBlank()) {
			sb.append("m").append("=").append(banner.encodeUrl(mobile)).append("&")
		}
		if (nationality.isNotBlank()) {
			sb.append("n").append("=").append(nationality).append("&")
		}
		if (age != 0.0) {
			sb.append("a").append("=").append(age).append("&")
		}

		if (latLng.isNotBlank()) {
			sb.append("ll").append("=").append(banner.encodeUrl(latLng)).append("&")
		}
		if (ipAddress.isNotBlank()) {
			sb.append("ip").append("=").append(banner.encodeUrl(ipAddress)).append("&")
		}

		if (preferredLanguage.isNotBlank()) {
			sb.append("pl").append("=").append(preferredLanguage).append("&")
		}
		if (memberSince.isNotBlank()) {
			sb.append("ms").append("=").append(memberSince).append("&")
		}
		if (merchantCustomerSegment.isNotBlank()) {
			sb.append("mcs").append("=").append(banner.encodeUrl(merchantCustomerSegment)).append("&")
		}

		if (totalSpend != 0.0) {
			sb.append("ts").append("=").append(totalSpend).append("&")
		}
		if (totalSpendInLast3Months != 0.0) {
			sb.append("ts3m").append("=").append(totalSpendInLast3Months).append("&")
		}
		if (totalSpendInLastYear != 0.0) {
			sb.append("ts1y").append("=").append(totalSpendInLastYear).append("&")
		}
		if (rewardBalance != 0.0) {
			sb.append("rb").append("=").append(rewardBalance).append("&")
		}
		sb.append("ccw").append("=").append(hasCCInWallet).append("&")
		if (ccBank.isNotBlank()) {
			sb.append("ccb").append("=").append(ccBank).append("&")
		}

		sb.append("sub").append("=").append(subscriber).append("&")
		if (pageCategory.isNotBlank()) {
			sb.append("pc").append("=").append(banner.encodeUrl(pageCategory)).append("&")
		}
		if (productType.isNotBlank()) {
			sb.append("pt").append("=").append(banner.encodeUrl(productType)).append("&")
		}
		if (version.isNotBlank()) {
			sb.append("v").append("=").append(banner.encodeUrl(version)).append("&")
		}
		if (platform.isNotBlank()) {
			sb.append("p").append("=").append(platform).append("&")
		}

		sb.deleteAt(sb.length - 1)
		return if (isRequestFromEktarCatalog) {
			sb.toString()
		} else {
			buildIFrameSnippet(sb.toString(), false)
		}
	}

	@JvmSynthetic
	internal fun buildIFrameSnippet(url: String, isRequestFromCatalog: Boolean): String {
		var height = "100%"
		var loadListener = ""
		if (isRequestFromCatalog) {
			height = "100vh"
			loadListener = "onload=dismissLoader()"
		}
		return """<html><head><meta name='viewport' content='width=device-width, shrink-to-fit=YES' initial-scale='1.0' maximum-scale='1.0' minimum-scale='1.0' user-scalable='no'/></head><body style="margin: 0; padding: 0"><iframe id="ek-iframe" style="width:100%; height:""" + height + """; border:none; display:flex; align-items:center; justify-content:center;" scrolling="no" """ + loadListener + """ src=""" + url + """></iframe></body></html>""".trimIndent()
	}
}
