import React, { useEffect } from "react";
import daas from "@ektar-tech/daas-sdk-js";
import EktarOfferCatalog from "../EktarOfferCatalog";

function StarbucksCatalogPage() {
  useEffect(() => {
    var starbucksId = localStorage.getItem("Starbucks");

    var daasEnv = localStorage.getItem("daas-env");

    var dataPoint = new daas.com.ektar.daas_sdk.model.CatalogDataPoint();
    dataPoint.merchantId = starbucksId;
    dataPoint.showLogo = true;
    dataPoint.showFooter = false;
    dataPoint.subscriber = true;
    dataPoint.utmSource = "starbucks";
    dataPoint.utmMedium = "bank-product-tab";

    var ektarCatalog = new daas.com.ektar.daas_sdk.core.EktarCatalog(dataPoint);
    ektarCatalog.ektar_daas_env = daasEnv;
    ektarCatalog.loadOfferCatalog();
  }, []);

  return (
    <EktarOfferCatalog />
  );
}

export default StarbucksCatalogPage;
