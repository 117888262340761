package com.ektar.daas_sdk.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
open class UtmParameters {

	// Utm Id
	@SerialName("utm_id")
	var utmId: String = ""

	// Utm Source
	@SerialName("utm_source")
	var utmSource: String = ""

	// Utm Medium
	@SerialName("utm_medium")
	var utmMedium: String = ""

	// Utm Campaign
	@SerialName("utm_campaign")
	var utmCampaign: String = ""

	// Utm Content
	@SerialName("utm_content")
	var utmContent: String = ""
}
